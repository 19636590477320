@import url("https://fonts.googleapis.com/css?family=Ubuntu&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .bg-gradient-vertical {
    @apply bg-gradient-to-b from-red to-primary;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* small css for the landing page mobile nav close */
#nav-mobile-btn.close span:first-child {
  transform: rotate(45deg);
  top: 4px;
  position: relative;
  background: #a0aec0;
}
#nav-mobile-btn.close span:nth-child(2) {
  transform: rotate(-45deg);
  margin-top: 0px;
  background: #a0aec0;
}

.hide-file-item-info .file-item-full-container-container .file-item-name,
.hide-file-item-info
  .files-ui-file-mosaic-main-container
  .files-ui-file-mosaic-file-name {
  display: none;
}

.hide-file-item-info
  .file-item-full-container-container
  .file-item-icon-container
  .file-item-footer
  .size-open-info {
  display: none;
}

.hide-file-item-info .file-item-full-container-container {
  display: flex;
  justify-content: center;
}

.compact-file-item
  .file-item-full-container-container
  .file-item-icon-container,
.compact-file-item
  .files-ui-file-mosaic-main-container
  .files-ui-file-mosaic-icon-layer-container {
  height: 90px;
  width: 90px;
}

/* Accordion Full Width */
.mantine-1avyp1d {
  width: 100%;
}

@media screen and (max-width: 500px) {
  /* for accordion child items remove excessive padding on the left */
  .mantine-37c67z {
    padding-left: 0px;
  }
}

/* Alternate date input */
.alt .mantine-1sd8r2y {
  /* display: flex;
  justify-content: center; */
  position: inherit;
  border: none;
  margin: auto;
}

/* .alt .mantine-Input-wrapper {
} */

/* Zoom Client initial styles */
#zmmtg-root {
  display: none;
}

.post-it {
  /* width: 250px; */
  min-height: 400px;
  position: relative;
  background: #ffa;
  overflow: hidden;
  margin: 30px auto;
  padding: 20px;
  border-radius: 0 0 0 30px/45px;
  box-shadow: inset 0 -40px 40px rgba(0, 0, 0, 0.2),
    inset 0 25px 10px rgba(0, 0, 0, 0.2), 0 5px 6px 5px rgba(0, 0, 0, 0.2);
  font-family: "Ubuntu", sans-serif;
  line-height: 1.7em;
  font-size: 20px;
  /* -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC); */

  /* color: #130d6b; */
}

.post-it .compact {
  min-height: none;
}

.post-it ol li,
.rte-custom ol li {
  /* cursor: pointer; */
  list-style: decimal !important;
  margin-left: 20px;
}

.post-it ul li,
.rte-custom ul li {
  /* cursor: pointer; */
  list-style: disc !important;
  margin-left: 20px;
}

.post-it a,
.rte-custom a {
  color: #ce2021 !important;
  cursor: pointer;
}

.post-it .ql-tooltip,
.rte-custom .ql-tooltip {
  left: 0 !important;
}

.post-it:before {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 25px;
  background: #ffa;
  box-shadow: 3px -2px 10px rgba(0, 0, 0, 0.2),
    inset 15px -15px 15px rgba(0, 0, 0, 0.3);
  left: 0;
  bottom: 0;
  z-index: 2;
  transform: skewX(25deg);
}

.post-it:after {
  content: "";
  display: block;
  position: absolute;
  width: 75%;
  height: 20px;
  border-top: 3px solid #a993257d;
  border-radius: 50%;
  bottom: 0px;
  left: 10%;
}

.p-large {
  padding-left: 40px !important;
}

.post-it-card h2 {
  font-size: x-large;
  font-weight: bolder;
}

/* For Post It Modal and Form View */
.post-it-modal .mantine-Paper-root,
.post-it-form .mantine-MultiSelect-input,
.post-it-form .mantine-Dropzone-root,
.post-it-form input:not([type="checkbox"], [type="radio"]),
.post-it-form textarea {
  background-color: #ffa !important;
}

label {
  text-align: left;
}

.fancy-icon {
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.fancy-text {
  font-family: "Fjalla One", sans-serif;
  text-shadow: #ce2021 1px 1px, #ffa 2px 2px, #fea204 2px 2px;
  letter-spacing: 1.5px;
}

/* input[type="time"] {
  background-color: #ce2021;
} */

input.custom-checkbox[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: "fea204";
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.3em;
  height: 1.3em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input.custom-checkbox[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #ce2021;
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input.custom-checkbox[type="checkbox"]:checked::before {
  transform: scale(1);
}

/* input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
} */

input.custom-checkbox[type="checkbox"]:disabled {
  /* --form-control-color: #959495; */
  color: #959495;
  cursor: not-allowed;
}

.pushable {
  /* background: hsl(340deg 100% 32%); */
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
}
.front {
  display: block;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.25rem;
  /* background: hsl(345deg 100% 47%); */
  color: white;
  transform: translateY(-6px);
}

.pushable:active .front {
  transform: translateY(-2px);
}

.masthead {
  position: relative;
  overflow: hidden;
  padding-top: calc(7rem - 72px);
  padding-bottom: 6rem;
  background: linear-gradient(0deg, #f9a11b 0%, #c11f25 100%);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
}

.masthead .masthead-content {
  z-index: 1;
  position: relative;
}

.masthead .masthead-content .masthead-heading {
  font-size: 3rem;
}

.masthead .masthead-content .masthead-subheading {
  font-size: 1rem;
}

.masthead .bg-circle {
  z-index: 0;
  position: absolute;
  border-radius: 100%;
  background: linear-gradient(0deg, #ce2126 0%, #f9a11b 100%);
}

.masthead .bg-circle-1 {
  height: 90rem;
  width: 90rem;
  bottom: -55rem;
  left: -55rem;
}

.masthead .bg-circle-2 {
  height: 50rem;
  width: 50rem;
  top: -25rem;
  right: -25rem;
}

.masthead .bg-circle-3 {
  height: 20rem;
  width: 20rem;
  bottom: -10rem;
  right: 5%;
}

.masthead .bg-circle-4 {
  height: 30rem;
  width: 30rem;
  top: -5rem;
  right: 35%;
}

@media (min-width: 992px) {
  .masthead {
    padding-top: calc(8rem - 55px);
    padding-bottom: 8rem;
  }
  .masthead .masthead-content .masthead-heading {
    font-size: 6rem;
  }
  .masthead .masthead-content .masthead-subheading {
    font-size: 4rem;
  }
}

body::-webkit-scrollbar,
div::-webkit-scrollbar {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  width: 9px;
  height: 5px;
}

body::-webkit-scrollbar-thumb {
  background: linear-gradient(13deg, #ce2126 14%, #f9a11b 64%);
  border-radius: 1px;
  height: 5px;
  background-clip: content-box;
}
body::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(13deg, #f9a11b 14%, #ce2126 64%);
}
body::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 6px;
  box-shadow: inset 7px 6px 6px #f0f0f0;
}

div::-webkit-scrollbar-thumb {
  background: linear-gradient(13deg, #ce2126 14%, #f9a11b 64%);
  border-radius: 10px;
}
div::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(13deg, #f9a11b 14%, #ce2126 64%);
}
div::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: inset 7px 10px 12px #f0f0f0;
}
